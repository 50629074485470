import React from "react";
import { Icon, Label } from "semantic-ui-react";

export function Introduction() {
  function getExperience() {
    let dob = "20050801";
    let year = Number(dob.substr(0, 4));
    let month = Number(dob.substr(4, 2)) - 1;
    let day = Number(dob.substr(6, 2));
    let today = new Date();
    let age = today.getFullYear() - year;
    if (
      today.getMonth() < month ||
      (today.getMonth() === month && today.getDate() < day)
    ) {
      age--;
    }

    return age;
  }

  return (
    <div id="main" style={{ fontSize: 18 }}>
      <section>
        <header
          className="major"
          style={{ textAlign: "justify", paddingRight: "20px" }}
        >
          <h2>Hi there,</h2>
        </header>
        <br />

        <p style={{ textAlign: "justify", paddingRight: "20px" }}>
          With a remarkable{" "}
          <b>
            <span>{getExperience()}</span>-year
          </b>{" "}
          career spanning various prestigious corporations such as GIVE, LeadSquared,
          ODCEM Technologies, Cisco, CA, and Olacabs, I've held influential
          roles in both Senior Technology and Management Positions. But my
          journey doesn't stop there – I'm also a proud Co-Founder of two
          successful ventures: Loctell Solutions and Demcon Technologies Pvt.
          Ltd.
        </p>
        <p style={{ textAlign: "justify", paddingRight: "20px" }}>
          Loctell Solutions, which I established, addresses the ever-expanding
          market for location-based services. Based in the heart of
          technological excellence, Bangalore, our company boasts a team of
          highly talented engineers from premier institutions who consistently
          deliver fair, transparent, and timely services to clients all across
          the nation. Through our unwavering commitment to quality and
          dedication, Loctell Solutions has carved a niche for itself in the
          cutting-edge field of location-based services.
        </p>

        <p style={{ textAlign: "justify", paddingRight: "20px" }}>
          One of my proudest achievements is the creation of InfuraaClub – a
          groundbreaking platform tailored exclusively for contractors. By
          providing them with affordable access to construction equipment and
          services, InfuraaClub empowers contractors to manage and monitor their
          rental needs efficiently. Our intuitive mobile app platform simplifies
          the entire process, making it a breeze for contractors to find and
          book the resources they require.
        </p>

        <p style={{ textAlign: "justify", paddingRight: "20px" }}>
          But my expertise doesn't end there. Throughout my journey, I've
          mastered an impressive array of technologies, from the foundational
          Java to advanced concepts like Spring Boot, Multi-Threading,
          Algorithms, and Data Structures. My proficiency extends to the realms
          of web and mobile development with technologies like React Native,
          HTML, CSS, and JavaScript, enabling me to create seamless and engaging
          user experiences.
        </p>

        <p style={{ textAlign: "justify", paddingRight: "20px" }}>
          On the backend, I have extensive knowledge of databases, including
          MySQL, Cassandra, MongoDB, and Elastic Search, as well as various
          tools and frameworks like Hibernate, RabbitMQ, Kafka, and Jenkins. In
          addition, I am well-versed in cloud services like GCP, AWS, and Azure,
          and have honed my skills in containerization with Docker and
          orchestration using Kubernetes.
        </p>

        <p style={{ textAlign: "justify", paddingRight: "20px" }}>
          The extensive list of technologies I've worked with showcases my
          insatiable appetite for learning and staying up-to-date with the
          ever-evolving tech landscape. It's not just a career for me –
          technology is my passion, and I'm committed to harnessing its power to
          drive innovation and solve real-world challenges.
        </p>

        <p style={{ textAlign: "justify", paddingRight: "20px" }}>
          If you're as passionate about technology as I am, join me on my blog,
          where I delve deeper into various tech topics, share insights from my
          experiences, and explore the exciting future that technology holds.
        </p>

        <p style={{ textAlign: "justify", paddingRight: "20px" }}>
          So, whether you're an individual looking for cutting-edge solutions or
          a company seeking a tech-savvy professional who can drive innovation
          and success, I am here to make an impact and elevate your
          technological endeavors to new heights. Let's shape the future
          together!
        </p>

        <Label style={{ margin: 5, padding: 10 }}>
          <a rel="noreferrer" target="_blank" href={"https://blog.amithgc.com"}>
            Go to my Blog&nbsp;&nbsp;
            <Icon name="right arrow" />
          </a>
        </Label>
      </section>
    </div>
  );
}
