import React from "react";
import {Breadcrumb, Button, Card, Divider, Icon, Image} from 'semantic-ui-react'
import {Link} from "react-router-dom";

export function AllArtProjects({heading, recent}) {

    const [data, setData] = React.useState([]);
    const [projectCount, setProjectCount] = React.useState(0);

    const sections = [
        {key: 'Home', content: 'Home', 'href': '/'},
        {key: 'All Projects', content: 'All Projects', active: true},
    ];

    React.useEffect(() => {
        getData()
    });

    function getData() {
        fetch('data/artprojects.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                let size = myJson.artprojects.length;
                setProjectCount(size);
                size = size - 4;
                if (recent) {
                    myJson.artprojects.splice(0, size);
                }
                setData(myJson)
            });
    }

    function getLink(item) {
        return (
            <div key={item.exlink}>
                <p as={Link} to={item.exlink}>
                    <Icon name='world'/>
                    {item.name}
                </p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
        );
    }

    function getProjectsCard(item) {
        let image = "../images/artprojects/" + item.folder + "/logo.png";
        let url = "/projectDetails?id=" + item.id;
        return (
            <Card raised={true} href={url} key={item.id}>
                <Image src={image} wrapped ui={false}/>
                <Card.Content>
                    <Card.Header>{item.name}</Card.Header>
                    <Card.Meta>
                        <span className='date'>{item.date}</span> | <span className='date'>{item.type}</span>
                    </Card.Meta>
                    <Card.Description>
                        {item.description}
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    {item.links && item.links.map(item => (
                        getLink(item)
                    ))}
                </Card.Content>
            </Card>
        )
    }

    return (
        <div id="main">
            <section>
                {!recent &&
                <Breadcrumb icon='right angle' sections={sections}/>
                }
                <header className="major" style={{"textAlign": "justify", "paddingRight": "20px"}}>
                    <header className="major">
                        <h2>{heading}</h2> {!recent && <small>{projectCount} Projects in Total</small>} <Divider/><br/>
                    </header>

                    <Card.Group itemsPerRow={2}>
                        {data && data.artprojects && data.artprojects.reverse().map(item => (
                            getProjectsCard(item)
                        ))}
                    </Card.Group>

                    {recent &&
                    <div>
                        <br/>
                        <Button primary icon labelPosition='right' as={Link} to="/projects">
                            View All {projectCount} Projects
                            <Icon name='right arrow'/>
                        </Button>
                    </div>
                    }
                </header>
            </section>
        </div>
    );
}
