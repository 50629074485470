import './App.css';
import 'semantic-ui-css/semantic.min.css'
import './assets/css/main.css';
import React from "react";
import {MainPage} from "./pages/MainPage";
import {BrowserRouter} from "react-router-dom";
import {Route, Routes} from "react-router";
import {BlankPage} from "./pages/BlankPage";
import {ProjectsListPage} from "./pages/art/ProjectsListPage";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/">
                  <Route index element={<MainPage />} />
                  <Route path="projects" element={<ProjectsListPage />} />
                  <Route path="*" element={<BlankPage />} />
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
