import React from "react";
import { Label, Icon, Card, Image} from "semantic-ui-react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import { formatText } from "../utils/Utils";

export function BlogsList() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    getData();
  }, []);

  function getData() {
    fetch('https://blog.amithgc.com/imagessitemap.json'
        , {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    )
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            setData(myJson)
        });
}

function formatDate(inputDate) {
  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];

  const dateParts = inputDate.split(" ");
  const dateString = dateParts[0];

  const [year, month, day] = dateString.split("-").map(part => parseInt(part));
  const formattedDate = `${day} ${months[month - 1]}, ${year}`;

  return formattedDate;
}

function getBlogCard(item) {
  let image = "https://blog.amithgc.com/" + item.img;
  let date = formatDate(item.date);
  let link = "https://blog.amithgc.com/" + item.location;

  return (
    <Card raised={true} key={item.title} fluid>
        <Image src={image} wrapped ui={false}/>
        <Card.Content>
            <Card.Header as='a' href={link} target="_blank">{formatText(item.title)}</Card.Header>
            <Card.Description>
                {formatText(item.summary)}
            </Card.Description>
        </Card.Content>
        <Card.Content extra>
        <span className='date'>{date}</span>
        </Card.Content>
    </Card>
  );
}

  return (
    <div id="main">
      <section>
        <Header
          as="h2"
          content="My Blogs"
          subheader="List of all my Blog Posts"
        />
        <hr />

        <Card.Group itemsPerRow={1} stackable>
          {data && data.map(item => (
              getBlogCard(item)
          ))}
        </Card.Group>

      </section>
      <br />
      <Label style={{margin: 0, padding: 10}}>
                    <a rel="noreferrer" target="_blank" href={"https://blog.amithgc.com"}>View All other Blog Posts&nbsp;&nbsp;
                    <Icon name='right arrow'/></a></Label>
    </div>
  );
}
