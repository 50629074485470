import React from "react";
import {SideBar} from "../SideBar";
import {AllArtProjects} from "./AllArtProjects";

export function ProjectsListPage() {
    return (
        <div>
            <SideBar/>
            <AllArtProjects heading={"All Art Projects"} recent={false}/>
        </div>
    );
}