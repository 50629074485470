import React from "react";
export function SideBar() {

    function getRandomImage () {
        let rand = Math.floor(Math.random() * 10);

        switch (rand) {
            case 0: return "../images/amith_gc1.png";
            case 1: return "../images/amith_gc2.png";
            case 2: return "../images/amith_gc3.png";
            case 3: return "../images/amith_gc4.png";
            case 4: return "../images/amith_gc5.png";
            case 5: return "../images/amith_gc6.png";
            case 6: return "../images/amith_gc7.png";
            case 7: return "../images/amith_gc8.png";
            case 8: return "../images/amith_gc9.png";
            case 9: return "../images/amith_gc10.png";
            default: return "../images/amith_gc1.png"
        }
    }

    return (
        <>
            <header id="header">
                <div className="inner">
                    <a href="/" className="image avatar"><img src={getRandomImage()} alt="Amith GC"/></a>
                    <h1><strong>Amith Chandrappa</strong><br/>
                        Engineering Head at <a href="https://www.give.do/">GIVE</a>.
                    </h1>
                    <br/>
                    <p className="about-me">I am a Result driven IT leader with expertise envisioning and
                        leading technology-based growth
                        initiatives.
                        Fast track management career marked by demonstrated ability to build peak-performing teams and
                        achieve
                        cross-functional business objectives. Experienced in both startups as well as large
                        corporations.</p>
                </div>

                <footer id="footer" className="desktop-only">
                    <div className="inner">
                        <ul className="icons">
                            <li><a rel="noreferrer" target="_blank" href="https://twitter.com/amithgc" className="icon fa-twitter"><span
                                className="label">Twitter</span></a>
                            </li>
                            <li><a rel="noreferrer" target="_blank" href="https://github.com/amithgc?tab=repositories"
                                   className="icon fa-github"><span
                                className="label">Github</span></a></li>
                            <li><a rel="noreferrer" target="_blank" href="https://facebook.com/amithgc"
                                   className="icon fa-facebook"><span className="label">Github</span></a>
                            </li>
                            <li><a rel="noreferrer" target="_blank"
                                   href="https://linkedin.com/in/amithgc"
                                   className="icon fa-linkedin"><span className="label">Github</span></a>
                            </li>
                            <li><a rel="noreferrer" target="_blank"
                                   href="https://play.google.com/store/apps/developer?id=Nineteen+Technologies"
                                   className="icon fa-android"><span className="label">Github</span></a>
                            </li>
                        </ul>

                        <ul className="copyright">
                            <li>&copy; amith.co.in &nbsp;|&nbsp;amithgc.com &nbsp; Amith GC</li>
                        </ul>

                        <a id="follow-button" title="Follow Amith GC (@amithgc) on Twitter" target="_blank"
                           href="https://twitter.com/amithgc"><i></i><span className="label"
                                                                           id="l">Follow <b>@amithgc</b></span></a>
                            <br />
                    </div>
                </footer>
            </header>
            <style jsx>{`
                @media (max-width: 768px) {
                    .about-me {
                        text-align: center;
                        padding-left: 0 !important;
                    }
                    .desktop-only {
                        display: none;
                    }
                }
                @media (min-width: 769px) {
                    .about-me {
                        text-align: right;
                        padding-left: 100px;
                    }
                }
                .image.avatar img {
                    border: 3px solid #555555;
                    border-radius: 50%;
                }
            `}</style>
        </>
    );
}