export function formatText(text) {
    // Replace &#39; with '
    let replacedText = text.replace(/&#39;/g, "'");

     // Replace &amp;rsquo; with '
    replacedText = replacedText.replace(/&amp;rsquo;/g, "'");
  
    replacedText = replacedText.replace(/&lt;/g, '<');
    replacedText = replacedText.replace(/&gt;/g, '>');
    replacedText = replacedText.replace(/&quot;/g, '"');
    replacedText = replacedText.replace(/&amp;/g, '&');

    return replacedText;
  }
