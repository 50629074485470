import React from "react";
import {Item, Label, Message} from 'semantic-ui-react'
import Header from "semantic-ui-react/dist/commonjs/elements/Header";

export function HobbyProjects() {

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        getData()
    }, []);

    function getData() {
        fetch('data/hobby-projects.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson)
            });
    }

    function getReviews(item) {
        return (
            <Message.Item key={item.name}>{item.name} <a rel="noreferrer" target="_blank"
                                                         href={item.exlink}>{item.exlinkname}</a> </Message.Item>
        );
    }

    function getProjectCard(item) {
        let image = "../images/hobby-projects/" + item.image;
        return (
            <Item key={item.name}>
                <div className="project-card">
                    <img src={image} alt={item.name} className="project-logo" />
                    <Item.Content>
                        <Item.Header as='a' style={{ fontWeight: 'bold', color: 'black', fontSize: '1.2em' }}>{item.name}</Item.Header>
                        <Item.Meta>
                            <span className='cinema'>{item.subName}</span>
                        </Item.Meta>
                        <Item.Description>{item.description}</Item.Description>
                        <Item.Extra>
                            <Label icon='calendar' content={item.type}/>
                            {item.exlink &&
                            <Label><a rel="noreferrer" target="_blank" href={item.exlink}>{item.exlinkname}</a></Label>
                            }

                            {item.reviews &&
                            <Message>
                                <Message.Header>External Links</Message.Header>
                                <Message.List>
                                    {item.reviews && item.reviews.map(item => (
                                        getReviews(item)
                                    ))}
                                </Message.List>
                            </Message>
                            }
                        </Item.Extra>
                    </Item.Content>
                </div>
                <style jsx>{`
                    .project-card {
                        position: relative;
                        color: #343434;
                    }
                    .project-logo {
                        width: 100px;
                        margin-right: 20px;
                        border: 1px solid #cccccc;
                        border-radius: 10px;
                        padding: 5px;
                    }
                    @media (max-width: 768px) {
                        .project-logo {
                            width: 50px;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                `}</style>
            </Item>
        )
    }

    return (
        <div id="main">
            <section>
                <Header
                    as='h2'
                    content='Hobby Projects'
                    subheader='List of all my Hobby Projects'
                />
                <hr/>
                <Item.Group divided>

                    {data && data.projects && data.projects.map(item => (
                        getProjectCard(item)
                    ))}
                </Item.Group>
            </section>
        </div>
    );
}
