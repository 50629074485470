import React from "react";
import { Label, Icon, Card, Image} from "semantic-ui-react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import { formatText } from "../utils/Utils";

export function StoriesList() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    getData();
  }, []);

  function getData() {
    fetch('https://stories.amithgc.com/imagessitemap.json'
        , {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    )
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            setData(myJson)
        });
}

  function formatDate(inputDate) {
    const months = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];

    const dateParts = inputDate.split(" ");
    const dateString = dateParts[0];

    const [year, month, day] = dateString.split("-").map(part => parseInt(part));
    const formattedDate = `${day} ${months[month - 1]}, ${year}`;

    return formattedDate;
  }

  function getStoriesCard2 (item) {
    let image = item.img;
    let date = formatDate(item.date);
    let link = item.location;
    return (
        <Card raised={true} key={item.title} fluid>
            <Image src={image} wrapped ui={false}/>
            <Card.Content>
                <Card.Header as='a' href={link} target="_blank">{formatText(item.title)}</Card.Header>
                <Card.Description>
                    {formatText(item.summary)}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
            <span className='date'>{date}</span>
            </Card.Content>
        </Card>
    )
  }

  return (
    <div id="main">
      <section>
        <Header
          as="h2"
          content="Automated Children Stories [🧒👧]"
          subheader="My kids were craving stories that would transport them to faraway lands and spark their imaginations. So, I conjured up something truly remarkable—an automated website that hosts captivating tales just for them! Here are few of the Stories which are generated using GPT."
        />
        <hr />
        <Card.Group itemsPerRow={1} stackable>
          {data && data.map(item => (
              getStoriesCard2(item)
          ))}
        </Card.Group>
      </section>
      <br />
      <Label style={{margin: 0, padding: 10}}>
                    <a rel="noreferrer" target="_blank" href={"https://stories.amithgc.com"}>Read All other Stories&nbsp;&nbsp;
                    <Icon name='right arrow'/></a></Label>
    </div>
  );
}
