import React from "react";
import { Icon } from "semantic-ui-react";

export function SkillSet() {

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        getData()
    }, []);

    function getData() {
        fetch('data/skills.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson)
            });
    }

    function getSkillCard(item) {
        let image = "../images/technologies/" + item.image;
        return (
            <div className="skill-card" key={item.name}>
                <img src={image} alt={item.name}/>
                <span>{item.name}</span>
            </div>
        )
    }

    return (
        <div id="main">
            <section className="skills-section">
                <h2>Technical Expertise <Icon name="code" /></h2>
                <p>In my journey, I've mastered a diverse set of technologies, including:</p>
                <div className="skills-container">
                    {data && data.skills && data.skills.map(item => getSkillCard(item))}
                </div>
            </section>
            <style jsx>{`
                .skills-section {
                    background-color: #f8f9fa;
                    padding: 40px 20px;
                    border-radius: 10px;
                    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
                }
                h2 {
                    text-align: center;
                    color: #333;
                    margin-bottom: 20px;
                }
                p {
                    text-align: center;
                    color: #666;
                    margin-bottom: 30px;
                }
                .skills-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                    gap: 10px;
                    justify-content: center;
                    padding: 15px;
                }
                .skill-card {
                    background-color: white;
                    border-radius: 8px;
                    padding: 15px;
                    text-align: center;
                    transition: transform 0.3s ease;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
                }
                .skill-card:hover {
                    transform: translateY(-5px);
                }
                .skill-card img {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 5px;
                }
                .skill-card span {
                    display: block;
                    font-size: 14px;
                    color: #333;
                }
                @media (max-width: 768px) {
                    .skills-container {
                        grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
                    }
                    .skill-card {
                        padding: 10px;
                    }
                    .skill-card img {
                        width: 40px;
                        height: 40px;
                        margin-bottom: 5px;
                    }
                    .skill-card span {
                        font-size: 12px;
                    }
                }
            `}</style>
        </div>
    );
}
