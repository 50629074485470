import React from "react";
import {Item, Label} from 'semantic-ui-react'
import Header from "semantic-ui-react/dist/commonjs/elements/Header";

export function WorkExperience() {

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        getData()
    }, []);

    function getData() {
        fetch('data/experience.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson)
            });
    }
    
    function getExperienceCard(item) {
        let image = "../images/companies/" + item.image;
        let exp = `${item.from} - ${item.to}`;
        return (
            <Item key={item.name}>
                <div className="experience-card">
                    <img src={image} alt={item.name} className="company-logo" />
                    <Item.Content>
                        <Item.Header as='a' style={{ fontWeight: 'bold', color: 'black', fontSize: '1.2em' }}>{item.designation}</Item.Header>
                        <Item.Meta>
                            <span className='cinema'>{item.name}</span>
                        </Item.Meta>
                        <Item.Description>{item.description}</Item.Description>
                        <Item.Extra>
                            <Label icon='calendar' content={exp}/>
                        </Item.Extra>
                    </Item.Content>
                </div>
                <style jsx>{`
                    .experience-card {
                        position: relative;
                        color: #343434;
                    }
                    .company-logo {
                        width: 100px;
                        margin-right: 20px;
                        border: 1px solid #cccccc;
                        border-radius: 10px;
                        padding: 5px;
                    }
                    @media (max-width: 768px) {
                        .company-logo {
                            width: 50px;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                `}</style>
            </Item>
        )
    }

    return (
        <div id="main">
            <section>
                <Header
                    as='h2'
                    content='Work Experience'
                    subheader='List of all the companies I have worked in'
                />
                <hr/>
                <Item.Group divided>

                    {data && data.experiences && data.experiences.map(item => (
                        getExperienceCard(item)
                    ))}
                </Item.Group>
            </section>
        </div>
    );
}
